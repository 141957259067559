import { Event } from '../types';
var trackEvent = function (action, label) {
    // @ts-ignore
    if (typeof ga !== 'function') {
        return;
    }
    // @ts-ignore
    // eslint-disable-next-line no-undef
    if (typeof ga.getAll !== 'function') {
        return;
    }
    // @ts-ignore
    var tracker = ga.getAll()[0]; // eslint-disable-line
    if (!tracker) {
        return;
    }
    tracker.send('event', 'Eddy AI Assistant', action, label);
};
// eslint-disable-next-line complexity
var sendChatEventsToGA = function (event) {
    var chatEvent = event.detail;
    switch (chatEvent.type) {
        case Event.CLICKED_ON_PREVIEW: {
            trackEvent('Clicked on Preview', 'Clicked on Preview');
            break;
        }
        case Event.SEND_BUTTON_CLICKED: {
            trackEvent('Sent Message', 'Sent Message | Text Message');
            break;
        }
        case Event.CLICKED_QUICK_REPLY: {
            var QRClickedEvent = chatEvent;
            trackEvent('Sent Message', "Sent Message | Quick Reply | " + QRClickedEvent.label);
            break;
        }
        case Event.CLICKED_CARD: {
            var cardClickedEvent = chatEvent;
            trackEvent('Clicked on Card', cardClickedEvent.card_title + " | " + cardClickedEvent.button_title);
            break;
        }
        case Event.RECEIVED_FLIGHT_OFFERS: {
            var flightResultsEvent = chatEvent;
            trackEvent('Received Flight Offers', flightResultsEvent.departure_location + " to " + flightResultsEvent.destination_location + " | " + flightResultsEvent.departure_date + " to " + flightResultsEvent.return_date);
            break;
        }
        case Event.CLICKED_ON_FLIGHT_OFFER: {
            var event_1 = chatEvent;
            trackEvent('Clicked on Flight Offer', event_1.departure_location + " to " + event_1.destination_location + " | " + event_1.departure_date + " to " + event_1.return_date + " | Total Price: " + event_1.currency + " " + event_1.price + " | Passengers: " + event_1.adult_passengers + " | Class: " + event_1.cabin_class);
            break;
        }
        case Event.RECEIVED_ACCOMMODATION_OFFERS: {
            var accommodationResultsEvent = chatEvent;
            trackEvent('Received Accommodation Offers', accommodationResultsEvent.location + " | " + accommodationResultsEvent.check_in_date + " to " + accommodationResultsEvent.check_out_date);
            break;
        }
        case Event.CLICKED_ON_ACCOMMODATION_OFFER: {
            var e = chatEvent;
            trackEvent('Clicked on Accommodation Offer', e.location + " | " + e.check_in_date + " to " + e.check_out_date + " | Total Price: " + e.currency + " " + e.total_price + " | Type: " + e.accommodation_type + " | Rooms: " + e.rooms + " | Stars: " + e.stars);
            break;
        }
        case Event.RECEIVED_ACTIVITY_OFFERS: {
            var e = chatEvent;
            trackEvent('Received Activity Offers', e.location);
            break;
        }
        case Event.CLICKED_ON_ACTIVITY_OFFER: {
            var e = chatEvent;
            trackEvent('Clicked on Activity Offer', e.location + " | " + e.title + " | Total Price: " + e.currency + " " + e.price);
            break;
        }
        case Event.RECEIVED_CAR_RENTAL_OFFERS: {
            var e = chatEvent;
            trackEvent('Received Car Rental Offers', e.pick_up_location + " to " + e.drop_off_location + " | " + e.pick_up_time + " to " + e.drop_off_time);
            break;
        }
        case Event.CLICKED_ON_CAR_RENTAL_OFFER: {
            var e = chatEvent;
            trackEvent('Clicked on Car Rental Offer', e.pick_up_location + " to " + e.drop_off_location + " | " + e.pick_up_time + " to " + e.drop_off_time + " | Total Price: " + e.currency + " " + e.total_price + " | Vehicle: " + e.vehicle);
            break;
        }
        case Event.WIDGET_CLOSED: {
            trackEvent('Chat Widget Closed', 'Chat Widget Closed');
            break;
        }
        case Event.WIDGET_OPENED: {
            trackEvent('Chat Widget Opened', 'Chat Widget Opened');
            break;
        }
        case Event.WIDGET_LOADED: {
            trackEvent('Chat Widget Loaded', 'Chat Widget Loaded');
            break;
        }
        case Event.WIDGET_DISPLAYED: {
            trackEvent('Chat Widget Displayed', 'Chat Widget Displayed');
            break;
        }
        case Event.CLICKED_ON_MESSAGE_LINK: {
            var e = chatEvent;
            if (e.url) {
                trackEvent('Clicked on Message Link', e.url);
            }
            break;
        }
        case Event.PREVIEW_CLOSED: {
            trackEvent('Preview Closed', 'Preview Closed');
        }
    }
};
(function () {
    var w = document.querySelector('#__EDDY_TRAVELS_CHAT_WIDGET');
    var initEventListener = function (widget) {
        // @ts-ignore
        widget.addEventListener('__EDDY_TRAVELS_CHAT_EVENT', sendChatEventsToGA);
    };
    if (w) {
        initEventListener(w);
        return;
    }
    new MutationObserver(function (mutationRecords, observer) {
        Array.from(document.querySelectorAll('#__EDDY_TRAVELS_CHAT_WIDGET')).forEach(function (widget) {
            if (widget) {
                initEventListener(widget);
                observer.disconnect();
            }
        });
    }).observe(document.documentElement, {
        childList: true,
        subtree: true
    });
})();
