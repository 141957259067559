export var WidgetState;
(function (WidgetState) {
    WidgetState[WidgetState["Initializing"] = 0] = "Initializing";
    WidgetState[WidgetState["Initialized"] = 1] = "Initialized";
    WidgetState[WidgetState["Updated"] = 2] = "Updated";
})(WidgetState || (WidgetState = {}));
export var WidgetOpenState;
(function (WidgetOpenState) {
    WidgetOpenState[WidgetOpenState["Initial"] = 0] = "Initial";
    WidgetOpenState[WidgetOpenState["Open"] = 1] = "Open";
    WidgetOpenState[WidgetOpenState["Closed"] = 2] = "Closed";
})(WidgetOpenState || (WidgetOpenState = {}));
export var Event;
(function (Event) {
    Event["CLICKED_ON_PREVIEW"] = "CLICKED_ON_PREVIEW";
    Event["SEND_BUTTON_CLICKED"] = "SEND_BUTTON_CLICKED";
    Event["CLICKED_QUICK_REPLY"] = "CLICKED_QUICK_REPLY";
    Event["CLICKED_CARD"] = "CLICKED_CARD";
    Event["LIKED_OFFER"] = "LIKED_OFFER";
    Event["UNLIKED_OFFER"] = "UNLIKED_OFFER";
    Event["RECEIVED_FLIGHT_RESULTS"] = "RECEIVED_FLIGHT_RESULTS";
    Event["RECEIVED_ACCOMMODATION_RESULTS"] = "RECEIVED_ACCOMMODATION_RESULTS";
    Event["RECEIVED_FLIGHT_OFFERS"] = "RECEIVED_FLIGHT_OFFERS";
    Event["CLICKED_ON_FLIGHT_OFFER"] = "CLICKED_ON_FLIGHT_OFFER";
    Event["RECEIVED_ACCOMMODATION_OFFERS"] = "RECEIVED_ACCOMMODATION_OFFERS";
    Event["CLICKED_ON_ACCOMMODATION_OFFER"] = "CLICKED_ON_ACCOMMODATION_OFFER";
    Event["RECEIVED_ACTIVITY_OFFERS"] = "RECEIVED_ACTIVITY_OFFERS";
    Event["CLICKED_ON_ACTIVITY_OFFER"] = "CLICKED_ON_ACTIVITY_OFFER";
    Event["RECEIVED_CAR_RENTAL_OFFERS"] = "RECEIVED_CAR_RENTAL_OFFERS";
    Event["CLICKED_ON_CAR_RENTAL_OFFER"] = "CLICKED_ON_CAR_RENTAL_OFFER";
    Event["COULD_NOT_UNDERSTAND"] = "COULD_NOT_UNDERSTAND";
    Event["WIDGET_CLOSED"] = "WIDGET_CLOSED";
    Event["WIDGET_OPENED"] = "WIDGET_OPENED";
    Event["WIDGET_LOADED"] = "WIDGET_LOADED";
    Event["WIDGET_DISPLAYED"] = "WIDGET_DISPLAYED";
    Event["WIDGET_HIDDEN"] = "WIDGET_HIDDEN";
    Event["CLICKED_ON_MESSAGE_LINK"] = "CLICKED_ON_MESSAGE_LINK";
    Event["PREVIEW_CLOSED"] = "PREVIEW_CLOSED";
})(Event || (Event = {}));
export var RTL_LANGUAGES = ['he', 'ar'];
